import React from 'react';
import Accordion, { AccordionItem, AccordionTitle, AccordionContent } from "../Accordion";

const AccordionWrap = () => {
    return (
        <div className="agency-accordion max-mb-n30">
            <Accordion>
                <AccordionItem id="one">
                    <AccordionTitle id="one">O que exatamente é branding?</AccordionTitle>
                    <AccordionContent id="one">Branding é mais do que um logotipo e um slogan inteligente. Sua marca conta a história de sua empresa. É sua mensagem e seus valores. Quando isso acontece, coisas notáveis acontecem.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="two">
                    <AccordionTitle id="two">Como é o processo de branding?</AccordionTitle>
                    <AccordionContent id="two">Branding é mais do que um logotipo e um slogan inteligente. Sua marca conta a história de sua empresa. É sua mensagem e seus valores. Quando isso acontece, coisas notáveis acontecem.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="three">
                    <AccordionTitle id="three">Que tipo de serviços você oferece?</AccordionTitle>
                    <AccordionContent id="three">Branding é mais do que um logotipo e um slogan inteligente. Sua marca conta a história de sua empresa. É sua mensagem e seus valores. Quando isso acontece, coisas notáveis acontecem.</AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default AccordionWrap;
