import React from 'react';
import {Link} from "react-router-dom";

const SidebarBanner = () => {
    return (
        <div className="sidebar-widget-content">
            <div className="sidebar-widget-banner overlay-two" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/breadcrumb-bg.jpg)`}} >
                <h3 className="title">Assine a nossa newsletter</h3>
                <p>Todas as últimas notícias de marketing, incluindo atualizações sobre campanhas de marca e os insights mais interessantes</p>
                <Link to={process.env.PUBLIC_URL + "/"}>Inscreva-se agora</Link>
            </div>
        </div>
    )
}

export default SidebarBanner;
