import React, { useState, useEffect, useRef } from "react";
import {Link} from "react-router-dom";
import Parallax from 'parallax-js';
import ModalVideo from "react-modal-video";

const HomeAboutThree = () => {
    const [isOpen, setOpen] = useState(false)
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="video-section section section-padding-t90-b100">
            <div className="container">
                <div className="row">

                    <div className="col-lg-8 m-auto" data-aos="fade-up">
                        <div className="about-content-full text-center mb-lg-10 mb-0">
                            <h2 className="title">Empresa focada em visão, produto e pessoas</h2>
                            <p>O marketing sempre foi uma questão de conectar-se com seu público-alvo no 
                                lugar certo e na hora certa. Hoje, isso significa que você precisa conhecê-los onde 
                                eles já estão passando tempo: na internet. Um profissional de marketing receptivo 
                                experiente pode dizer que o marketing receptivo e o marketing digital são virtualmente a 
                                mesma coisa, mas existem algumas pequenas diferenças.</p>

                            <Link to={process.env.PUBLIC_URL + "/"} className="link"> <mark>Ler mais</mark> </Link>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="video-popup-area">
                            <div className="skill-video">
                                <img className="image" src={process.env.PUBLIC_URL + "images/video/about-video.jpg"} alt="video popup" />
                                <button className="icon" onClick={()=> setOpen(true)}><i className="fas fa-play"></i></button>
                                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="eS9Qm4AOOBY" onClose={() => setOpen(false)}/>
                            </div>

                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1">
                                    <img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" />
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default HomeAboutThree;
